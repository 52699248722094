import { createTheme } from '@mui/material'
import TopCVTheme from '../../../topcv.com/src/styles'
import merge from 'lodash/merge'

export const TopCVCoUKTheme = createTheme(
  merge({}, TopCVTheme, {
    themeName: 'TopCV',
  })
)

export default TopCVCoUKTheme
