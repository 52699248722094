module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"79aca5f427f97839abeee1d602e41d97","enableOnDevMode":true},
    },{
      plugin: require('../../gatsby-theme-ecom/gatsby-browser.ts'),
      options: {"plugins":[],"siteMetadata":{"title":"TopCV","siteUrl":"https://topcv.co.uk","brandName":"TopCV.co.uk","trailingSlash":false,"trustpilot":{"businessUnitId":"553662c40000ff00057ee92f","reviewTags":["CareerEvolution","Cover-Letter","ExecutivePriority","Interview-prep","LinkedIn","Military","ProfessionalGrowth","ResumeRefresh","ResumeReview","shown-on-website","ThankYouLetter"]},"trustpilotTopInterview":{"businessUnitId":"59fa2e5f0000ff0005af48c3","reviewTags":["interview-prep","shown-on-website"]},"siteID":126,"locale":"en-GB","i18nextLocale":"en","defaultResumeProduct":"CV","googleTagManagerCode":"GTM-56P875R","intercomWidgetColor":"#038dff","intercomAppId":"sxbbc2de","kameleoonId":"mw4t122342","domainName":"topcv.co.uk"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
